export const data = {
    REGION : "eu-west-2",
    CARDS_CONNECT_REGION : "eu-west-2",
    POOL : "eu-west-2:6ab95965-50aa-4e95-86f6-84bed075f946",
    INITIATE_CHAT_NAME : "vb-initiatechat-lambda",
    CLOSE_CHAT_NAME : "vb-closechat-lambda",
    GET_MEETING_FLAG : "vb-getmeetingflag-lambda",
    GENERATE_SIGNATURE : "vb-generatesignature-lambda",
    AGENT_AVAILABILITY_STATUS : "vb-agentstatus-lambda",
    GET_PARTICIPANTS_COUNT: "vb-get-participant-details-lambda",
	PURGE_DATA : "vb-purgedata-lambda",
    GET_SSM_PARAMETER : "vb-getssmparameters-lambda",
    ALERT_MESSAGE : "All our Agents are busy at this moment, please try again after sometime.",
    AGENT_TIMER1 : 180000,
    AGENT_TIMER2 : 120000,
    REDIR_TIMER : 1000,
    WAITING_PADDING : 40,
    WAITING_PADDING2 : 0,
    POOLING_TIME_AGENT_AVAILABLITY_CALL : 30000,
    POOLING_TIME_MEETING_FLAG : 3000,
    RE_ROUTE_PAGE_URL : 'exit',
    WAITING_TIME: 3,
    ZOOM_WEBSDKLIB_PATH: '/@zoomus/websdk/',
    BUSINESS_FUNCTION: "RBS Video Banker",
    PRODUCTTILEBGCOLOR : "#15366e",
    BRAND : "RBS",
    FOOTNOTE: "Royal Bank of Scotland Plc",
    PIC_FORMAT : "png",
    ZOOM_TIMER : 1000,
    ZOOM_RETRY : 2,
    PAGE_SUB_ONE_TEXT:
    'Please take a moment to watch the Video which contains some important information we need to tell you before your Video Banking appointment',
    VIDEO_URL : "https://rbs.qumucloud.com/view/MF8WkPRZ3QO?autoplay=0#/",
    BOOK_APPOINTMENT_URL :"https://bookings.rbs.co.uk/cep-booktimeslot/" ,
	MAX_ZOOM_JOIN_ATTEMPTS: 3,
    ZOOM_JOIN_TIMER: 15000,
    ZOOM_MAC_URL: "zoommtg://zoom.us/join",
    ZOOM_IOS_URL: "zoomus://zoom.us/join",
    POOLING_TIME_PARTICIPANT_COUNT: 5000,
    PARTICIPANT_COUNT_TIMER:60000,
	PARTICIPANT_BUTTON_TIMER:90000,
    ENV: "PROD"
}
